export const EMAIL_FROM = 'malu@goodfellas.app'
export const SITE_URL = 'https://stage.goodfellas.app/'
export const POSTHOG_HOST = 'https://us.i.posthog.com'
export const BOT_PORT = '3003'
export const GOOGLE_GA4 = 'G-JB65EGBJC1'
export const ZENVIA_PHONENUMBER = '5511999999999'
export const COOKIE_POLICY =
  'https://www.iubenda.com/privacy-policy/62069539/cookie-policy'
export const FIREBASE_CONFIG_PROJECT_ID = 'fourth-sphere-375015'
export const IOS_URL = 'itms-apps://itunes.apple.com/app/id1550000000'
export const URL_TIKTOK = 'https://www.tiktok.com/@goodfellasapp'
export const FIREBASE_CONFIG_APP_ID =
  '1:178082627359:web:5bdd9d8900fc602c5a3a2a'
export const APP_ID = 'Goodfellas'
export const ANDROID_URL = 'market://details?id=com.goodfellas.app'
export const VAPID_PUBLIC_KEY =
  'BEz0tWFvrgmMK39h5PpNpoLHMO0l5zS4apePoauuzwyyW-JApwJ4zv_pyDzdT2oee68KogIwRRV03F4WvgQ6jkU'
export const PHONE_NUMBER = '5514998543094@c.us'
export const ENVIRONMENT = 'stage'
export const FACEBOOK_APP_ID = '218356404011320'
export const PRIVACY_POLICY_URL =
  'https://www.iubenda.com/privacy-policy/62069539'
export const IUBENDA = 'PuhrdnapSu6R2zaElsMVBIbzokiHwOmm'
export const FACEBOOK_PIXEL = '715128906830359'
export const TERMS_OF_SERVICE_URL =
  'https://www.iubenda.com/terms-and-conditions/62069539'
export const INVESTORS_URL = 'https://wellfound.com/company/goodfellas-1'
export const URL_INSTAGRAM = 'https://www.instagram.com/goodfellasapp/'
export const EMAIL_NAME =
  'Malu Fernandes, Goodfellas Customer Care \uD83E\uDD4B'
export const FIREBASE_CONFIG_MESSAGING_SENDER_ID = '178082627359'
export const GOOGLE_MAPS_API_KEY = 'AIzaSyDAG8sKtgI7xrusgAnKTLBmGL2xzxopxi8'
export const URL_TWITTER = 'https://twitter.com/goodfellas_app'
export const API_VERSION = '1.0.0'
export const API_HOST = 'flowstate.stage.goodfellas.app'
export const API_URL = 'https://flowstate.stage.goodfellas.app/'
export const URL_FACEBOOK =
  'https://www.facebook.com/profile.php?id=100090369510216'
export const HELP_URL = 'https://direct.lc.chat/15815874/'
export const APP_VERSION = '1.0.0'
export const API_PORT = '443'
export const APP_URL = 'https://combat-stage.goodfellas.app/'
export const FIREBASE_CONFIG_STORAGE_BUCKET = 'fourth-sphere-375015.appspot.com'
export const FIREBASE_CONFIG_MEASUREMENT_ID = 'G-VNJYR51LSQ'
export const POSTHOG_KEY = 'phc_Cj9rE2QjCFL9JvVxdyO4zti5AIub9nGWQFF9GvjcWJF'
export const FIREBASE_CONFIG_APIKEY = 'AIzaSyCIajn8qxZjHNlueSDzepk32CYtRzwbMQ4'
export const FIREBASE_CONFIG_AUTH_DOMAIN =
  'fourth-sphere-375015.firebaseapp.com'
export const JKS_PASSWORD = 'Gy5EtKCtCqtf49yFSSJUxlfRiTwN'
export const BOT_URL = 'https://jiulia-api-stage.goodfellas.app/'
export const BUILD_ID = '1e33526'
/**
 * Do not edit this file, it's generated automatically */
